import React, { useContext, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import UserContext from "../../context/userContext";
import { CAPTCHA_KEY } from "../../shared/constants/env";

const ChangeMyPassword = ({ tokenParam }) => {
  const [state, setState] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const userContext = useContext(UserContext);
  const recaptchaRef = React.createRef();

  const handleChange = (ev) => {
    setState({
      ...state,
      token: tokenParam,
      [ev.target.name]: ev.target.value,
    });
  };

  const handleCaptcha = (ev) => {
    setState({
      ...state,
      captcha: ev,
    });
  };

  const newPasswordIsValid = state.newPassword?.length >= 12;

  const numbersRegex = /^(?=.*\d)/m;
  const upperCaseRegex = /^(?=.*[A-Z])/m;
  const lowerCaseRegex = /^(?=.*[a-z])/m;
  const specialCharsRegex = /^(?=(?:.*[!@#$%^&*()\-_=+{};:,<.>]){1,})/m;

  const numbersRegexIsValid = numbersRegex.test(state.newPassword);
  const upperCaseRegexIsValid = upperCaseRegex.test(state.newPassword);
  const lowerCaseRegexIsValid = lowerCaseRegex.test(state.newPassword);
  const specialCharsRegexIsValid = specialCharsRegex.test(state.newPassword);

  const newPasswordValidation =
    newPasswordIsValid &&
    numbersRegexIsValid &&
    upperCaseRegexIsValid &&
    lowerCaseRegexIsValid &&
    specialCharsRegexIsValid;

  const passwordPolicies = [
    { policy: "12 caracteres ou mais", valid: newPasswordIsValid },
    {
      policy: "Ao menos 01 letra maiúscula",
      valid: upperCaseRegexIsValid,
    },
    {
      policy: "Ao menos 01 letra minúscula",
      valid: lowerCaseRegexIsValid,
    },
    {
      policy: "Ao menos 01 número",
      valid: numbersRegexIsValid,
    },
    {
      policy: "Ao menos 01 caractere especial (Exemplos: @, !, #, $)",
      valid: specialCharsRegexIsValid,
    },
    {
      policy: "Atingir a complexidade mínima",
      valid: newPasswordValidation,
    },
  ];

  return (
    <div className="rulesPassword">
      <div className="validationContainer">
        <span className="policiesTitle">Política de Senha</span>

        <ul className="policiesList">
          {passwordPolicies.map(({ policy, valid }) => (
            <li key={policy} className={`policyItem ${valid ? "valid" : ""}`}>
              {policy}
            </li>
          ))}
        </ul>
      </div>
      <div className="formItemPortal">
        <form
          onSubmit={(e) => {
            recaptchaRef.current.reset();
            return userContext.changeMyPassword(e, state);
          }}
        >
          <input
            type="password"
            onChange={handleChange}
            autoComplete="off"
            name="newPassword"
            placeholder="NOVA SENHA"
          />
          <input
            type="password"
            onChange={handleChange}
            autoComplete="off"
            name="confirmPassword"
            placeholder="CONFIRMAR NOVA SENHA"
          />
          <div className="formRecaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={CAPTCHA_KEY}
              onChange={handleCaptcha}
            />
          </div>
          <button>Redefinir senha</button>
        </form>
      </div>
    </div>
  );
};

export default ChangeMyPassword;
