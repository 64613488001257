import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import UserContext from "../../context/userContext";
import { ANALYTICS_ID } from "../../shared/constants/env";

const GoogleAnalytics = (props) => {
  const { children } = props;

  const location = useLocation();

  const userContext = useContext(UserContext);
  const userId = userContext?.user?.me?.id;

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", ANALYTICS_ID, {
      user_id: userId || "Usuário não logado",
      cookie_expires: 0,
    });
  }, [location.pathname, userId]);

  return children;
};

export default GoogleAnalytics;
