import React, { useContext, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import UserContext from "../../context/userContext";
import { CAPTCHA_KEY } from "../../shared/constants/env";

const ResetMyPassword = () => {
  const [state, setState] = useState({});

  const userContext = useContext(UserContext);
  const recaptchaRef = React.createRef();

  const handleChange = (ev) => {
    setState({
      ...state,
      [ev.target.name]: ev.target.value,
    });
  };

  const handleCaptcha = (ev) => {
    setState({
      ...state,
      captcha: ev,
    });
  };

  return (
    <div className="formItemPortal">
      <form
        onSubmit={(e) => {
          recaptchaRef.current.reset();
          return userContext.sendResetMyPassword(e, state);
        }}
      >
        <input
          type="text"
          onChange={handleChange}
          autoComplete="off"
          name="cnpj"
          placeholder="CNPJ"
        />
        <input
          type="text"
          onChange={handleChange}
          autoComplete="off"
          name="email"
          placeholder="E-mail"
        />
        <div className="formRecaptcha">
          <ReCAPTCHA
            sitekey={CAPTCHA_KEY}
            onChange={handleCaptcha}
            ref={recaptchaRef}
          />
        </div>
        <button>Enviar</button>
      </form>
    </div>
  );
};

export default ResetMyPassword;
