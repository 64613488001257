const {
    // Endpoints
    REACT_APP_BASE_URL: BASE_URL,
  
    // Auth
    REACT_APP_API_KEY: API_KEY,
    REACT_APP_CAPTCHA_KEY: CAPTCHA_KEY,
    REACT_APP_CLIENT_ID: CLIENT_ID,
    REACT_APP_SCOPE: SCOPE,
    REACT_APP_ANALYTICS_ID: ANALYTICS_ID,
    REACT_APP_AUTHORIZATION_KEY: AUTHORIZATION_KEY,
  
    // Thesaurus
    REACT_APP_THESAURUS_SINOPSE_ARCO: THESAURUS_SINOPSE_ARCO,
    REACT_APP_THESAURUS_ROTEIRO_EPISODIO: THESAURUS_ROTEIRO_EPISODIO,
    REACT_APP_THESAURUS_MATERIAL_COMPLEMENTAR: THESAURUS_MATERIAL_COMPLEMENTAR,
  } = process.env;
  
  const PARCERIA_URL = `${BASE_URL}`;
  
  export {
    BASE_URL,
    PARCERIA_URL,
    API_KEY,
    CAPTCHA_KEY,
    CLIENT_ID,
    SCOPE,
    ANALYTICS_ID,
    AUTHORIZATION_KEY,
    THESAURUS_SINOPSE_ARCO,
    THESAURUS_ROTEIRO_EPISODIO,
    THESAURUS_MATERIAL_COMPLEMENTAR,
  };
  